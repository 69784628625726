import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que en temps humit presenta al capell una capa gelatinosa, aquest fa fins a 3 cm de diàmetre, hemisfèric al principi tornant pla i convex amb un mamelló central obtús de color més clar que la resta que és de color caramel. Les làmines estan lliures, espaiades amb nombroses lamel·les de diferents llargàries i de color gris amb l’aresta més blanca. El peu és prim, cilíndric i té una zona més obscura tacada per la caiguda de les espores. Aquestes són de violàcies negroses a porpra violaci en massa, el·líptiques, d’11-12 x 7-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      